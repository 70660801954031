import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import styles from "./scss/success.module.scss"
const Success = props => {
  return (
    <Layout>
      <section className={`${styles.success} section`}>
        <div className="banner">
          <h2 className="ribbon">
            <span className="top">&nbsp;</span>
            <span className="text">
              {props.data.contentfulSuccessPage.title}
            </span>
            <span className="bottom">&nbsp;</span>
          </h2>
        </div>

        {documentToReactComponents(props.data.contentfulSuccessPage.body.json)}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulSuccessPage {
      title
      body {
        json
      }
    }
  }
`

export default Success
